import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

function AccordionItem({ sno, question, answer }) {
  return (
    <li className="text-left">
      <label
        htmlFor={`accordion-${sno}`}
        className="relative flex flex-col rounded-md border border-gray-100 shadow-md"
      >
        <input className="peer hidden" type="checkbox" id={`accordion-${sno}`} />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="absolute right-0 top-4 ml-auto mr-5 h-4 text-gray-500 transition peer-checked:rotate-180"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth="2"
        >
          <path
            d="M19 9l-7 7-7-7"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <div className="relative ml-4 cursor-pointer select-none items-center py-4 pr-12">
          <h3 className="text-sm text-gray-600 font-bold lg:text-base">{question}</h3>
        </div>
        <div className="max-h-0 overflow-hidden transition-all duration-500 peer-checked:max-h-96">
          <div className="p-5">
            <p className="text-sm">{answer}</p>
          </div>
        </div>
      </label>
    </li>
  );
}

function FAQ() {
  const [faqItems, setFaqItems] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  useEffect(() => {
    let data = JSON.stringify({
      "appname": "storepoa"
    });
    
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://www.storepoa.com/common/faqlist.php',
      headers: { 
        'Content-Type': 'application/json', 
        'Authorization': 'Basic ZGF2ZWJpejpBYnVAMDgyMQ=='
      },
      data : data
    };
    
    axios.request(config)
    .then((response) => {
      console.log(JSON.stringify(response.data));
      setFaqItems(response.data);
      setIsLoading(false);
    })
    .catch((error) => {
      console.log(error);
    });
  }
  , []);

  return (
    <div className="relative mx-auto w-full py-16 px-5 font-sans text-gray-800 sm:px-20 md:max-w-screen-lg lg:py-24">
      <h2 className="mb-5 text-center font-sans text-4xl sm:text-5xl font-bold">
        Frequently asked Questions
      </h2>
      <p className="mb-12 text-center text-lg text-gray-600">
        We have written down answers to some of the frequently asked questions.
        But, if you still have any queries, feel free to contact us.
      </p>
      {isLoading ? ( // Render loading symbol if isLoading is true
        <div className="flex justify-center">
          <svg
            className="animate-spin h-5 w-5 text-gray-500"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647zM12 20c3.042 0 5.824-1.135 7.938-3l-2.647-3A7.962 7.962 0 0112 16v4zm5.291-6A7.962 7.962 0 0112 20v4c4.418 0 8-3.582 8-8h-4zm-2.647-3A7.962 7.962 0 0116 12h4c0-4.418-3.582-8-8-8v4zm-3-2.647A7.962 7.962 0 0112 4V0c-4.418 0-8 3.582-8 8h4z"
            ></path>
          </svg>
        </div>
      ) : (
        <ul className="space-y-4">
          {faqItems.map((item) => (
            <AccordionItem key={item.sno} {...item} />
          ))}
        </ul>
      )}
      <div className="mt-20 flex justify-center">
        <Link
          to={"/contact"}
          className="inline-flex cursor-pointer rounded-lg bg-blue-500 py-3 px-5 text-lg text-white"
          href="#"
        >
          Still have questions?
        </Link>
      </div>
    </div>
  );
}

export default FAQ;
