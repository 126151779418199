// Products.js

import React from "react";
import cloud from "../assets/cloud.jpg";
import saas from "../assets/saas.jpg";
import dev from "../assets/dev.jpg";
import bit from "../assets/bit.jpg";
import web from "../assets/web.jpg";
import ai from "../assets/ai.jpg";

function AnimatedProduct({ title, description, imgUrl }) {
  return (
    <div className="product-container">
      <div className="flex flex-col items-center justify-center md:flex-row">
        <img
          className="h-56 rounded-lg object-contain md:order-2 md:object-left"
          src={imgUrl}
          alt=""
        />
        <div className="">
          <h3 className="text-4xl">{title}</h3>
          <p className="mt-6 text-base text-gray-500">
            <i>{description}</i>
          </p>
          {/* <button className="mt-4 rounded-lg bg-blue-700 px-6 py-2 text-white transition hover:translate-y-1">
            Learn more
          </button> */}
        </div>
      </div>
    </div>
  );
}

function AnimatedProduct2({ title, description, imgUrl }) {
  return (
    <div className="product-container">
      <div class="flex flex-col items-center justify-center md:flex-row">
        <img
          class="h-56 rounded-lg  object-contain md:ml-0 md:object-left "
          src={imgUrl}
          alt=""
        />
        <div class="sm:ml-10">
          <h3 class="text-4xl">{title}</h3>
          <p class="mt-6 text-base text-gray-500">
            <i>{description}</i>{" "}
          </p>
          {/* <button class="mt-4 rounded-lg bg-blue-700 px-6 py-2 text-white transition hover:translate-y-1">
            Learn more
          </button> */}
        </div>
      </div>
    </div>
  );
}

function Service() {
  return (
    <div>
      <section className="mx-auto w-screen py-12 text-blue-700 sm:py-16 lg:py-20">
        <div className="mx-auto px-4 w-screen sm:px-6 lg:px-8">
          <div className="mx-auto max-w-2xl text-center">
            <p className="text-sm font-medium text-blue-800">INTRODUCING</p>
            <h2 className="mt-2 text-3xl font-bold sm:text-4xl xl:text-5xl">
              Our Services
            </h2>
            <hr className="mx-auto mt-4 h-2 w-32 border-none bg-blue-700" />
          </div>

          <div className="mt-10 grid grid-cols-1 gap-20 text-center sm:mx-auto w-sm md:mt-20 lg:w-3/5 md:text-left">
            <AnimatedProduct
              title="Cloud Computing"
              description="Unlock Infinite Possibilities: Embrace the future with our cloud solutions—scalable, reliable, and designed to elevate your business to new heights. Let's innovate together!"
              imgUrl={cloud}
            />
            <AnimatedProduct2
              title="Software as a Service (SaaS)"
              description="Seamless Software, Stress-Free: Experience hassle-free software management with our SaaS solutions. Say goodbye to complexities and hello to effortless efficiency. Ready to elevate your workflow?"
              imgUrl={saas}
            />
            <AnimatedProduct
              title="Software Development"
              description="Your Vision, Our Code: Imagine the perfect software for your business—we bring it to life. Tailored, dynamic, and backed by our unwavering support. Let's create something extraordinary!"
              imgUrl={dev}
            />
            <AnimatedProduct2
              title="Blockchain Technology"
              description="Secure, Transparent, Revolutionary: Join the blockchain revolution! Enhance security, transparency, and efficiency in transactions. Let’s pioneer a new era of possibilities."
              imgUrl={bit}
            />
            <AnimatedProduct
              title="Web Development"
              description="Websites that Wow: Your online presence matters. Our web development ensures stunning, responsive sites. Ready for a website that captivates and converts?"
              imgUrl={web}
            />
            <AnimatedProduct2
              title="Artificial Intelligence"
              description="Intelligent Solutions for Advanced Insights: Explore the potential of AI with our services, offering intelligent solutions for data analysis, automation, and advanced decision-making."
              imgUrl={ai}
            />
          </div>
        </div>
      </section>
    </div>
  );
}

export default Service;
