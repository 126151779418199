import React from "react";
import axios from "axios";

function ContactPage() {
  const [email, setEmail] = React.useState("");
  const [subject, setSubject] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [error, setError] = React.useState("");

  const handleEmailChange = (e) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(e.target.value);
    setEmail(e.target.value);
    setError(isValidEmail ? "" : "Please enter a valid email address.");
  };

  const handleSubjectChange = (e) => {
    setSubject(e.target.value);
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSend = () => {
    if (!email || !subject || !message) {
      setError("Please fill in all fields.");
      return;
    }

    let data = JSON.stringify({
      email: email,
      subject: subject,
      message: message,
      appname: "storepoa",
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://www.storepoa.com/common/saveinquiry.php",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Basic ZGF2ZWJpejpBYnVAMDgyMQ==",
      },
      data: data,
    };

    if (!error) { // Check if there is no error
      axios
        .request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
          if (response.data[0].status === 1) {
            alert("Message sent successfully!");
            setEmail("");
            setSubject("");
            setMessage("");
          }
          else {
            alert("Message not sent. Please try again.");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    else {
      alert(error);
    }
  };

  return (
    <div className="font-sans text-base text-gray-900 sm:px-10">
      <div className="text-base text-gray-900">
        <div className="mx-auto w-full sm:max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl">
          <div className="mx-2 pt-12 text-center md:mx-auto md:w-2/3 md:pb-12">
            <h1 className="mb-4 text-3xl font-black sm:text-5xl xl:text-6xl">
              Contact us
            </h1>
            <div className="text-lg sm:text-xl xl:text-xl">
              <div className="text-gray-900">
                {/* <p className="mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mx-auto mb-20 flex w-full max-w-screen-lg flex-col overflow-hidden rounded-xl text-gray-900 md:flex-row md:border md:shadow-lg">
        <div className="mx-auto w-full max-w-xl border-gray-200 px-10 py-8 md:px-8">
          <div className="mb-4">
            <label className="text mb-2 block font-medium" htmlFor="email">
              Your e-mail:
            </label>
            <input
              onChange={handleEmailChange}
              className="w-full rounded border border-gray-300 px-3 py-2 outline-none ring-blue-500 focus:ring"
              id="email"
              required=""
            />
          </div>
          <div className="mb-4">
            <label className="text mb-2 block font-medium" htmlFor="subject">
              Subject:
            </label>
            <input
              onChange={handleSubjectChange}
              className="w-full rounded border border-gray-300 px-3 py-2 outline-none ring-blue-500 focus:ring"
              id="subject"
              required=""
            />
          </div>
          <div className="mb-4">
            <label className="text mb-2 block font-medium" htmlFor="message">
              Message:
            </label>
            <textarea
              onChange={handleMessageChange}
              className="h-52 w-full rounded border border-gray-300 px-3 py-2 outline-none ring-blue-500 focus:ring"
              id="message"
              required=""
            ></textarea>
          </div>
          {error && <p className="text-red-500 mb-4">{error}</p>}
          <div className="flex items-center">
            <div className="flex-1"></div>
            <button
              onClick={handleSend}
              className="rounded-xl bg-blue-600 px-4 py-3 text-center font-bold text-white hover:bg-blue-700"
            >
              Send message
            </button>
          </div>
        </div>

        <div className="mt-10 bg-blue-600 px-10 py-8 text-gray-100 md:mt-0 md:ml-auto">
          <div>
            <p className="mb-4 font-medium border-b  pb-2">OFFICE HOURS</p>
            <p className="mb-4">Monday to Friday: 9:00 AM to 6:00 PM</p>
            <p className="mb-4">Weekend: Closed</p>
            <p className="mb-4">
              Email:
              <a href="#" className="font-semibold underline">
                support@storepoa.com
              </a>
            </p>
            <p className="mb-4">
              Phone:
              <a href="#" className="font-semibold underline">
                +254769343028
              </a>
            </p>
            <hr className="my-2 h-0 border-t border-r-0 border-b-0 border-l-0 border-gray-300" />
            {/* <p className="mb-4">Org.no: 63452-2832</p>
            <p className="mb-4">VAT no: 32353</p> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactPage;
