import React, { useState, useEffect } from "react";
import { SocialIcon } from "react-social-icons";
import { motion, AnimatePresence } from "framer-motion";
import { 
  FiPhone, FiMapPin, FiMail, FiArrowUp, FiGlobe, 
  FiCode, FiCloud, FiLock, FiCpu, FiServer, 
  FiClock, FiHeart, FiAward, FiCheckCircle 
} from "react-icons/fi";
import logo from "../assets/logo3.png";
import { getContacts } from "../utils/contacts";
import cloud from "../assets/cloud.jpg";
import saas from "../assets/saas.jpg";
import dev from "../assets/dev.jpg";
import bit from "../assets/bit.jpg";
import web from "../assets/web.jpg";
import ai from "../assets/ai.jpg";

const FooterSection = ({ title, children }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    whileInView={{ opacity: 1, y: 0 }}
    viewport={{ once: true }}
    className="space-y-6"
  >
    <h3 className="text-white text-lg font-semibold relative inline-block">
      {title}
      <span className="absolute -bottom-1 left-0 w-12 h-1 bg-gradient-to-r from-blue-500 to-blue-600 rounded-full"></span>
    </h3>
    {children}
  </motion.div>
);

const NewsletterForm = () => {
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus("subscribing");
    // Simulate API call
    await new Promise(resolve => setTimeout(resolve, 1000));
    setStatus("subscribed");
    setEmail("");
    setTimeout(() => setStatus(""), 3000);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-3">
      <div className="relative">
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your email"
          className="w-full px-4 py-2 rounded-lg bg-gray-800 border border-gray-700 
            focus:outline-none focus:border-blue-500 focus:ring-2 focus:ring-blue-500/20 transition-all"
          required
        />
        <AnimatePresence>
          {status === "subscribed" && (
            <motion.div
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0 }}
              className="absolute -top-6 left-0 text-green-400 text-sm flex items-center gap-1"
            >
              <FiCheckCircle /> Thanks for subscribing!
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <motion.button
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}
        disabled={status === "subscribing"}
        className={`w-full px-4 py-2 rounded-lg font-medium transition-all
          ${status === "subscribing" 
            ? "bg-gray-600 cursor-wait" 
            : "bg-gradient-to-r from-blue-600 to-blue-700 hover:from-blue-700 hover:to-blue-800"
          }`}
      >
        {status === "subscribing" ? "Subscribing..." : "Subscribe"}
      </motion.button>
    </form>
  );
};

const ServiceItem = ({ service }) => (
  <motion.li
    whileHover={{ x: 5 }}
    className="group flex items-start gap-3 p-3 rounded-lg hover:bg-gray-800/50 transition-all cursor-pointer"
  >
    <div className="p-2.5 bg-gradient-to-br from-blue-500/10 to-blue-600/10 rounded-lg 
      group-hover:from-blue-500/20 group-hover:to-blue-600/20 transition-all duration-300">
      {service.icon}
    </div>
    <div className="flex-1">
      <span className="text-sm font-medium text-white group-hover:text-blue-400 transition-colors block">
        {service.name}
      </span>
      <span className="text-xs text-gray-400 group-hover:text-gray-300 transition-colors mt-1 line-clamp-2">
        {service.description}
      </span>
    </div>
  </motion.li>
);

const Footer = () => {
  const [data, setData] = useState({
    address1: "",
    mobno: "",
    email: "",
  });
  const loadContacts = async () => {
    try {
      const res = await getContacts();
      setData(res);
    } catch (error) {
      console.error('Error loading contacts:', error);
    }
  };
  useEffect(() => {
    loadContacts();
  }, []);
  const [showScrollTop, setShowScrollTop] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setShowScrollTop(window.scrollY > 300);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);



  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const businessHours = [
    { day: "Monday - Friday", hours: "8:00 AM - 5:00 PM" },
    { day: "Saturday", hours: "8:00 AM - 12:00 PM" },
    { day: "Sunday", hours: "Closed" }
  ];

  return (
    <footer className="bg-gradient-to-b from-gray-900 to-black text-gray-300 pt-16 pb-8 relative">
      {/* Decorative top border */}
      <div className="absolute top-0 left-0 right-0 h-1 bg-gradient-to-r from-transparent via-blue-500 to-transparent opacity-30" />

      {/* Scroll to top button */}
      <AnimatePresence>
        {showScrollTop && (
          <motion.button
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            whileHover={{ scale: 1.1, y: -5 }}
            whileTap={{ scale: 0.95 }}
            onClick={scrollToTop}
            className="fixed bottom-8 right-8 p-3 bg-gradient-to-r from-blue-600 to-blue-700 
              text-white rounded-full shadow-lg hover:shadow-blue-500/20 z-50"
            aria-label="Scroll to top"
          >
            <FiArrowUp className="w-5 h-5" />
          </motion.button>
        )}
      </AnimatePresence>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12">
          {/* Company Info */}
          <div className="space-y-6">
            <motion.img
              whileHover={{ scale: 1.05 }}
              src={logo}
              alt="Store Poa Enterprise"
              className="w-40"
            />
            <p className="text-sm leading-relaxed text-gray-400">
              Your trusted partner in digital transformation. We empower businesses 
              and individuals with innovative solutions for sustainable growth and success.
            </p>
            <div className="flex flex-wrap gap-4">
              {socialLinks.map((link) => (
                <motion.div
                  key={link.url}
                  whileHover={{ scale: 1.1, y: -2 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <SocialIcon
                    url={link.url}
                    className="hover:opacity-90 transition-opacity"
                    style={{ height: 35, width: 35 }}
                  />
                </motion.div>
              ))}
            </div>
          </div>

          {/* Services Section */}
          <div className="lg:col-span-2">
            <FooterSection title="Our Services">
              <ul className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {services.map((service) => (
                  <ServiceItem key={service.name} service={service} />
                ))}
              </ul>
            </FooterSection>
          </div>

          {/* Contact & Hours */}
          <div className="space-y-8">
            <FooterSection title="Contact Us">
              <ul className="space-y-4">
                <motion.li 
                  whileHover={{ x: 5 }}
                  className="flex items-start gap-3 group"
                >
                  <div className="p-2 bg-blue-500/10 rounded-lg group-hover:bg-blue-500/20 transition-colors">
                    <FiMapPin className="w-5 h-5 text-blue-400" />
                  </div>
                  <span className="text-sm text-gray-400 group-hover:text-gray-300 transition-colors">
                    {data.address1}
                  </span>
                </motion.li>
                <motion.li 
                  whileHover={{ x: 5 }}
                  className="flex items-center gap-3 group"
                >
                  <div className="p-2 bg-blue-500/10 rounded-lg group-hover:bg-blue-500/20 transition-colors">
                    <FiPhone className="w-5 h-5 text-blue-400" />
                  </div>
                  <a 
                    href={`tel:${data.mobno}`}
                    className="text-sm text-gray-400 group-hover:text-gray-300 transition-colors"
                  >
                    {data.mobno}
                  </a>
                </motion.li>
                <motion.li 
                  whileHover={{ x: 5 }}
                  className="flex items-center gap-3 group"
                >
                  <div className="p-2 bg-blue-500/10 rounded-lg group-hover:bg-blue-500/20 transition-colors">
                    <FiMail className="w-5 h-5 text-blue-400" />
                  </div>
                  <a 
                    href={`mailto:${data.email}`}
                    className="text-sm text-gray-400 group-hover:text-gray-300 transition-colors"
                  >
                    {data.email}
                  </a>
                </motion.li>
              </ul>
            </FooterSection>

            <FooterSection title="Business Hours">
              <div className="space-y-2">
                {businessHours.map((schedule, index) => (
                  <div key={index} className="text-sm text-gray-400">
                    <span className="font-medium text-gray-300">{schedule.day}:</span>
                    <br />
                    <span>{schedule.hours}</span>
                  </div>
                ))}
              </div>
            </FooterSection>

            <FooterSection title="Newsletter">
              <p className="text-sm text-gray-400 mb-4">
                Stay updated with our latest news and exclusive offers.
              </p>
              <NewsletterForm />
            </FooterSection>
          </div>
        </div>

        {/* Bottom Bar */}
        <div className="mt-16 pt-8 border-t border-gray-800">
          <div className="flex flex-col md:flex-row justify-between items-center gap-4">
            <p className="text-sm text-gray-400">
              © {new Date().getFullYear()} Store Poa Enterprise Ltd. All rights reserved.
            </p>
            <div className="flex gap-6">
              {['Privacy Policy', 'Terms of Service', 'Cookie Policy'].map((item) => (
                <motion.a
                  key={item}
                  whileHover={{ x: 2, color: '#fff' }}
                  href="#"
                  className="text-sm text-gray-400 hover:text-white transition-colors"
                >
                  {item}
                </motion.a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

// Updated services data
const services = [
  { 
    name: 'Cloud Computing',
    icon: <FiCloud className="w-5 h-5 text-blue-400" />,
    description: "Unlock Infinite Possibilities: Embrace the future with our cloud solutions",
    image: cloud
  },
  { 
    name: 'Software as a Service',
    icon: <FiServer className="w-5 h-5 text-blue-400" />,
    description: "Seamless Software, Stress-Free: Experience hassle-free software management",
    image: saas
  },
  { 
    name: 'Software Development',
    icon: <FiCode className="w-5 h-5 text-blue-400" />,
    description: "Your Vision, Our Code: Imagine the perfect software for your business",
    image: dev
  },
  { 
    name: 'Blockchain Solutions',
    icon: <FiLock className="w-5 h-5 text-blue-400" />,
    description: "Secure, Transparent, Revolutionary: Join the blockchain revolution",
    image: bit
  },
  { 
    name: 'Web Development',
    icon: <FiGlobe className="w-5 h-5 text-blue-400" />,
    description: "Websites that Wow: Your online presence matters",
    image: web
  },
  { 
    name: 'Artificial Intelligence',
    icon: <FiCpu className="w-5 h-5 text-blue-400" />,
    description: "Intelligent Solutions for Advanced Insights",
    image: ai
  }
];

const socialLinks = [
  { url: "https://www.linkedin.com/in/storepoa-enterprise-b95606232/" },
  { url: "https://www.facebook.com/storepoa-101929082370281/" },
  { url: "https://twitter.com/STOREPOAENTERP1" },
  { url: "https://t.me/storepoa" },
  { url: "https://Wa.me/message/HG4BG4P7Z3X4G1" },
  { url: "https://youtube.com/" },
  { url: "https://instagram.com/storepoaenterprise1/" },
];

export default Footer;