import axios from "axios";

export const getContacts = async () => {

    let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "https://www.storepoa.com/biznew/admin/contacts.php",
        headers: {
        "Content-Type": "application/json",
        Authorization: "Basic aG9wZTE5ODpEYXZlQDJvMFo=",
        },
    };
    
    try {
        const response = await axios.request(config);
        return response.data;
    } catch (error) {
        //   console.error(error);
        return { status: 0 }; // Or return a specific error value/message
    }
    }