import React from "react";
import Header from "../components/Header";
import Products from "../components/Service";
import Stat from "../components/Stat";

function HomeScreen() {
  return (
    <div>
      <Header />
      <Products />
      <Stat />
    </div>
  );
}

export default HomeScreen;
