import React from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import HomeScreen from './pages/HomeScreen'
import Navbar from './components/Navbar';
import Footer from './components/footer';
import Service from './components/Service';
import ContactPage from './pages/ContactPage';
import Products from './components/Products';
import FAQ from './components/FAQ';
import ScrollToTopButton from './components/ScrollToTopButton';

function App() {
  return (
    <Router>
    <div className="App">
        <Navbar />
        <div className=" min-h-screen">
        <Routes>
            <Route
                exact
                path="/"
                element={<HomeScreen />}
            ></Route>
            <Route
                exact
                path="/products"
                element={<Products />}
            ></Route>
            <Route
                exact
                path="/services"
                element={<Service />}
            ></Route>
       
            <Route
                exact
                path="/faq"
                element={<FAQ />}
            ></Route>
            <Route
                exact
                path="/contact"
                element={<ContactPage />}
            ></Route>
        </Routes>
        </div>
        <Footer />
        {/* <ScrollToTopButton /> */}
    </div>
</Router>
  )
}

export default App