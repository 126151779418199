import React, { useState, useEffect } from "react";
import { motion, AnimatePresence, useScroll, useTransform } from "framer-motion";
import { FiArrowRight, FiShield, FiTrendingUp, FiClock, FiCheck, FiAward, FiUsers, FiGlobe, FiStar } from "react-icons/fi";

// Custom hook for intersection observer
function useInView(options = {}) {
  const [ref, setRef] = useState(null);
  const [isInView, setIsInView] = useState(false);

  useEffect(() => {
    if (!ref) return;
    const observer = new IntersectionObserver(([entry]) => {
      setIsInView(entry.isIntersecting);
    }, options);
    observer.observe(ref);
    return () => observer.disconnect();
  }, [ref, options]);

  return [setRef, isInView];
}

function ProductFeatureCard({ feature, index }) {
  const [ref, isInView] = useInView({ threshold: 0.2 });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 20 }}
      animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
      transition={{ duration: 0.5, delay: index * 0.1 }}
      whileHover={{ scale: 1.03, boxShadow: "0 4px 15px rgba(0,0,0,0.1)" }}
      className="flex items-center gap-3 p-4 rounded-xl bg-white border border-gray-100 hover:border-blue-200 transition-all"
    >
      <div className="p-2.5 bg-gradient-to-br from-blue-100 to-blue-200 rounded-lg text-white">
        {feature.icon}
      </div>
      <span className="text-sm font-medium text-gray-700">{feature.text}</span>
    </motion.div>
  );
}

function ProductItem({ title, imageUrl, description, url, features, benefits, testimonial, rating = 5, stats = [] }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const { scrollYProgress } = useScroll();
  const scale = useTransform(scrollYProgress, [0, 1], [1, 1.1]);

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.6 }}
      className="group relative bg-white rounded-2xl p-6 shadow-lg hover:shadow-2xl transition-all duration-500"
    >
      {/* Premium Badge */}
      {/* <motion.div
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        className="absolute -top-3 -right-3 bg-gradient-to-r from-blue-600 to-blue-800 text-white px-4 py-1 rounded-full text-sm font-semibold shadow-lg z-10"
      >
        Premium
      </motion.div> */}

      {/* Rating */}
      {/* <div className="absolute top-4 left-4 flex items-center gap-1 bg-white/90 backdrop-blur-sm px-3 py-1 rounded-full">
        {[...Array(5)].map((_, i) => (
          <FiStar key={i} className={`w-4 h-4 ${i < rating ? 'text-yellow-400 fill-current' : 'text-gray-300'}`} />
        ))}
        <span className="ml-1 text-sm font-medium text-gray-700">{rating}.0</span>
      </div> */}

      {/* Main Content */}
      <div className="space-y-6">
        {/* Title */}
        <motion.h3 
          whileHover={{ scale: 1.02 }}
          className="text-2xl font-bold bg-gradient-to-r from-blue-600 via-blue-700 to-blue-800 bg-clip-text text-transparent"
        >
          {title}
        </motion.h3>

        {/* Image Container */}
        <div className="relative overflow-hidden rounded-xl aspect-video bg-gray-100">
          <motion.img
            style={{ scale }}
            onLoad={() => setImageLoaded(true)}
            className={`w-full h-full object-cover transition-opacity duration-700 ${imageLoaded ? 'opacity-100' : 'opacity-0'}`}
            src={require(`../assets/${imageUrl}`)}
            alt={title}
            loading="lazy"
          />
          <AnimatePresence>
            {!imageLoaded && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="absolute inset-0 flex items-center justify-center"
              >
                <div className="w-8 h-8 border-4 border-blue-500 border-t-transparent rounded-full animate-spin" />
              </motion.div>
            )}
          </AnimatePresence>
        </div>

        {/* Stats Grid - Only show if stats exist */}
        {/* {stats.length > 0 && (
          <div className="grid grid-cols-3 gap-4">
            {stats.map((stat, index) => (
              <div key={index} className="text-center">
                <div className="text-2xl font-bold text-blue-600">{stat.value}</div>
                <div className="text-xs text-gray-500">{stat.label}</div>
              </div>
            ))}
          </div>
        )} */}

        {/* Description */}
        <motion.div
          animate={{ height: isExpanded ? 'auto' : '6rem' }}
          className="relative overflow-hidden"
        >
          <p className="text-gray-700 leading-relaxed">
            {description}
          </p>
          {!isExpanded && (
            <div className="absolute bottom-0 left-0 right-0 h-12 bg-gradient-to-t from-white to-transparent" />
          )}
        </motion.div>
        <button
          onClick={() => setIsExpanded(!isExpanded)}
          className="text-blue-600 hover:text-blue-700 text-sm font-medium flex items-center gap-1"
        >
          {isExpanded ? 'Show less' : 'Read more'}
          <motion.div
            animate={{ rotate: isExpanded ? 180 : 0 }}
            transition={{ duration: 0.3 }}
          >
            ↓
          </motion.div>
        </button>

        {/* Features Grid */}
        <div className="grid grid-cols-2 gap-4">
          {features.map((feature, index) => (
            <ProductFeatureCard key={index} feature={feature} index={index} />
          ))}
        </div>

        {/* Benefits */}
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          className="space-y-4"
        >
          <h4 className="font-semibold text-gray-900">Key Benefits</h4>
          <div className="grid gap-3">
            {benefits.map((benefit, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, x: -20 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ delay: index * 0.1 }}
                className="flex items-start gap-3 bg-gray-50 p-3 rounded-lg"
              >
                <FiCheck className="text-green-500 flex-shrink-0 mt-1" />
                <span className="text-sm text-gray-600">{benefit}</span>
              </motion.div>
            ))}
          </div>
        </motion.div>

        {/* Testimonial */}
        {/* {testimonial && (
          <motion.div
            whileHover={{ scale: 1.02 }}
            className="bg-gradient-to-br from-blue-50 to-blue-100 rounded-xl p-5"
          >
            <div className="flex items-start gap-4">
              <img
                src={testimonial.avatar}
                alt={testimonial.author}
                className="w-12 h-12 rounded-full object-cover"
              />
              <div>
                <p className="italic text-gray-700">"{testimonial.quote}"</p>
                <div className="mt-2 font-medium text-gray-900">
                  {testimonial.author}
                  <div className="text-sm text-gray-500">{testimonial.position}</div>
                </div>
              </div>
            </div>
          </motion.div>
        )} */}

        {/* CTA Button */}
        <motion.a
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          className="inline-flex items-center justify-center w-full px-6 py-3 text-sm font-medium text-white bg-gradient-to-r from-blue-600 to-blue-700 rounded-lg hover:from-blue-700 hover:to-blue-800 transition-all duration-300 shadow-lg hover:shadow-xl"
        >
          Explore {title}
          <FiArrowRight className="ml-2" />
        </motion.a>
      </div>
    </motion.div>
  );
}

function Products() {
  const productsData = [
    {
      title: "SPELMM",
      imageUrl: "spelmm.png",
      description:
        "Revolutionizing online investments through a perfect blend of automated interest accrual and expert financial oversight. Our seasoned professionals ensure your investments grow while maintaining security and compliance. With real-time monitoring and advanced risk management systems, we provide a secure and profitable investment environment.",
      url: "https://spelmm.com",
      rating: 5,
      stats: [
        { value: "99.9%", label: "Uptime" },
        { value: "24/7", label: "Support" },
        { value: "500K+", label: "Users" },
      ],
      features: [
        { icon: <FiShield className="text-blue-600" />, text: "Bank-grade security" },
        { icon: <FiTrendingUp className="text-blue-600" />, text: "Expert oversight" },
        { icon: <FiClock className="text-blue-600" />, text: "24/7 Automated" },
        // { icon: <FiAward className="text-blue-600" />, text: "Certified " },
      ],
      benefits: [
        "Automated interest accrual system",
        // "Professional financial oversight",
        "Real-time portfolio monitoring",
        "Comprehensive risk management",
      ],
      testimonial: {
        quote: "SPELMM has transformed how we handle our investments. The combination of automation and expert oversight gives us peace of mind.",
        author: "John Smith, Financial Director"
      }
    },
    {
      title: "E-commerce Solutions",
      imageUrl: "shop.png",
      description:
        "Transform your business with our comprehensive e-commerce platform. Featuring advanced analytics, seamless inventory management, and optimized checkout processes for maximum conversion rates.",
      url: "https://storepoa.com",
      rating: 5,
      stats: [
        { value: "1M+", label: "Transactions" },
        { value: "99.9%", label: "Uptime" },
        { value: "24/7", label: "Support" },
      ],
      features: [
        { icon: <FiShield className="text-blue-600" />, text: "Secure payments" },
        { icon: <FiTrendingUp className="text-blue-600" />, text: "Analytics" },
        { icon: <FiClock className="text-blue-600" />, text: "24/7 Support" },
        // { icon: <FiShield className="text-blue-600" />, text: "Mobile-first" },
      ],
      benefits: [
        "Advanced analytics for data-driven decisions",
        "Seamless inventory management for efficient operations",
        "Optimized checkout processes for increased conversions",
        // "Mobile-first design for enhanced user experience",
      ],
      testimonial: {
        quote: "The e-commerce platform has significantly improved our online sales. The combination of advanced analytics and mobile-first design has made our customers happy.",
        author: "Emily Johnson, E-commerce Manager"
      }
    },
    {
      title: "Point of Sale Systems",
      imageUrl: "pos.png",
      description:
        "Next-generation POS technology that streamlines operations and boosts efficiency. Features include real-time inventory tracking, staff management, and comprehensive reporting tools.",
      url: "https://storepoa.com/pos",
      rating: 5,
      stats: [
        { value: "50K+", label: "Stores" },
        { value: "100M+", label: "Sales" },
        { value: "99.9%", label: "Reliability" },
      ],
      features: [
        // { icon: <FiShield className="text-blue-600" />, text: "Offline mode" },
        { icon: <FiTrendingUp className="text-blue-600" />, text: "Real-time data" },
        { icon: <FiClock className="text-blue-600" />, text: "Quick checkout" },
        { icon: <FiShield className="text-blue-600" />, text: "Multi-location" },
      ],
      benefits: [
        // "Offline mode for uninterrupted operations",
        "Real-time data for informed decision-making",
        "Quick checkout for faster transactions",
        "Multi-location support for global businesses",
      ],
      testimonial: {
        quote: "The POS system has made our operations more efficient. The real-time data and multi-location support have helped us manage our business better.",
        author: "Michael Brown, Retail Manager"
      }
    },
  ];

  return (
    <div className="bg-gradient-to-b from-gray-50 to-white min-h-screen">
      <section className="mx-auto max-w-screen-xl py-20 px-4 sm:px-6 lg:px-8">
        {/* Enhanced Header */}
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="mx-auto max-w-3xl text-center mb-20"
        >
          <motion.p 
            whileHover={{ scale: 1.05 }}
            className="inline-block text-sm font-semibold text-blue-600 tracking-wide uppercase bg-blue-50 px-4 py-2 rounded-full mb-4"
          >
           Our Solutions
          </motion.p>
          <h2 className="mt-2 text-4xl font-bold text-gray-900 sm:text-5xl mb-6">
            Solutions for Businesses & Individuals
          </h2>
          <p className="text-xl text-gray-600 leading-relaxed">
            Discover our innovative tools designed to help both businesses and individuals thrive. 
            From streamlined operations to personal growth opportunities, we've got you covered.
          </p>
        </motion.div>

        {/* Products Grid with Filtering Options */}
        <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
          {productsData.map((product, index) => (
            <ProductItem key={index} {...product} />
          ))}
        </div>
      </section>
    </div>
  );
}

export default Products;
