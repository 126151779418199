import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  FiHome, FiPackage, FiSettings, FiPhone, 
  FiHelpCircle, FiX, FiMail, FiMapPin 
} from 'react-icons/fi';
import { getContacts } from '../utils/contacts';

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const location = useLocation();
  const [data, setData] = useState({
    address1: "",
    mobno: "",
    email: "",
  });
  const loadContacts = async () => {
    try {
      const res = await getContacts();
      setData(res);
    } catch (error) {
      console.error('Error loading contacts:', error);
    }
  };
  useEffect(() => {
    loadContacts();
  }, []);

  // Handle navbar background on scroll
  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 20);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const menuItems = [
    { path: '/products', name: 'Products', icon: <FiPackage />, description: 'Explore our innovative solutions' },
    { path: '/services', name: 'Services', icon: <FiSettings />, description: 'Professional services tailored for you' },
    { path: '/contact', name: 'Contact', icon: <FiPhone />, description: 'Get in touch with our team' },
    { path: '/faq', name: 'FAQ', icon: <FiHelpCircle />, description: 'Find answers to common questions' },
  ];

  return (
    <header 
      className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300 ${
        scrolled ? 'bg-white/90 backdrop-blur-md shadow-lg' : 'bg-transparent'
      }`}
    >
      <div className="max-w-screen-xl mx-auto px-4 py-4">
        <div className="flex items-center justify-between">
          {/* Logo */}
          <Link to="/" className="relative z-50">
            <motion.div 
              className="flex items-center space-x-2"
              whileHover={{ scale: 1.05 }}
            >
              <span className="w-10">
                <img src={require('../assets/logo3.png')} alt="StorePoa Logo" />
              </span>
              <span className={`text-2xl font-black ${scrolled || isOpen ? 'text-gray-900' : 'text-gray-800'}`}>
                StorePoa
              </span>
            </motion.div>
          </Link>

          {/* Desktop Navigation */}
          <nav className="hidden lg:block">
            <ul className="flex items-center space-x-8">
              {menuItems.map((item) => (
                <li key={item.name}>
                  <Link
                    to={item.path}
                    className={`relative py-2 text-sm font-medium transition-colors
                      ${location.pathname === item.path 
                        ? 'text-blue-600' 
                        : 'text-gray-700 hover:text-blue-600'
                      }`}
                  >
                    {item.name}
                    {location.pathname === item.path && (
                      <motion.div
                        layoutId="navbar-indicator"
                        className="absolute -bottom-1 left-0 right-0 h-0.5 bg-blue-600"
                        transition={{ type: "spring", bounce: 0.2, duration: 0.6 }}
                      />
                    )}
                  </Link>
                </li>
              ))}
        
            </ul>
          </nav>

          {/* Mobile Menu Button */}
          <button
            className="relative z-50 lg:hidden"
            onClick={() => setIsOpen(!isOpen)}
            aria-label="Toggle Navigation"
          >
            <motion.div
              animate={isOpen ? "open" : "closed"}
              className={`w-7 h-7 flex items-center justify-center ${
                isOpen ? 'text-white' : 'text-gray-700'
              }`}
            >
              {isOpen ? <FiX className="w-6 h-6" /> : (
                <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                </svg>
              )}
            </motion.div>
          </button>

          {/* Mobile Navigation */}
          <AnimatePresence>
            {isOpen && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2 }}
                className="fixed inset-0 z-40 lg:hidden"
              >
                <motion.div
                  initial={{ x: "100%" }}
                  animate={{ x: 0 }}
                  exit={{ x: "100%" }}
                  transition={{ type: "tween", duration: 0.3 }}
                  className="absolute inset-0 bg-gradient-to-b from-blue-600 to-blue-800"
                >
                  <div className="flex flex-col h-full px-6 pt-24 pb-6">
                    <nav className="flex-1">
                      <ul className="space-y-6">
                        <li>
                          <Link
                            to="/"
                            className="flex items-center space-x-4 text-white"
                            onClick={() => setIsOpen(false)}
                          >
                            <FiHome className="w-6 h-6" />
                            <div>
                              <span className="block text-xl font-semibold">Home</span>
                              <span className="text-sm text-blue-200">Back to homepage</span>
                            </div>
                          </Link>
                        </li>
                        {menuItems.map((item) => (
                          <motion.li
                            key={item.name}
                            whileHover={{ x: 10 }}
                            className="border-b border-blue-500/20 pb-4"
                          >
                            <Link
                              to={item.path}
                              className="flex items-center space-x-4 text-white"
                              onClick={() => setIsOpen(false)}
                            >
                              <div className="w-6 h-6">{item.icon}</div>
                              <div>
                                <span className="block text-xl font-semibold">{item.name}</span>
                                <span className="text-sm text-blue-200">{item.description}</span>
                              </div>
                            </Link>
                          </motion.li>
                        ))}
                      </ul>
                    </nav>

                    {/* Enhanced Contact Info for Mobile */}
                    <div className="mt-auto space-y-4">
                      <div className="flex items-center space-x-3 text-white/80">
                        <FiPhone className="w-5 h-5" />
                        <a  className="text-white hover:text-blue-200">
                          {data.mobno}
                        </a>
                      </div>
                      <div className="flex items-center space-x-3 text-white/80">
                        <FiMail className="w-5 h-5" />
                        <a href={`mailto:${data.email}`} className="text-white hover:text-blue-200">
                          {data.email}
                        </a>
                      </div>
                      <div className="flex items-center space-x-3 text-white/80">
                        <FiMapPin className="w-5 h-5" />
                        <span className="text-sm">{data.address1}</span>
                      </div>
                    </div>
                  </div>
                </motion.div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </header>
  );
}

export default Navbar;